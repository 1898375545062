import React from 'react'
import style from './imagecard.module.scss'

const ImageCard = (data) => {
  return (
    <div className={`${data.className} ${style.imagecard} u-img`}>
      <div className={style.imagecard__badge}>{data.top ? data.top : null}</div>

      {data.url ? (
        <img alt="" className={style.imagecard__picture} />
      ) : (
        data.picture
      )}

      <div className={style.imagecard__text}>{data.children}</div>
    </div>
  )
}

export default ImageCard
