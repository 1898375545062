import { format } from 'date-fns'
import { Link } from 'gatsby'
import React from 'react'
import { Clock } from 'react-feather'
import ArticleDate from 'src/components/article-date/article-date'
import Badge from 'src/components/badge/badge'
import ImageCard from 'src/components/imagecard/imagecard'
import style from './article-emphasis.module.scss'

const ArticleEmphasis = ({ withLink, withMeta, article }) => {
  const articleImage = (
    <img
      src={article.frontmatter.picture}
      alt={article.frontmatter.title}
      loading="lazy"
      className={`${style.article__img} u-img u-shadow`}
    />
  )

  const duration = (
    <Badge className={style.article__duration}>
      <Clock size={16} />
      &nbsp; Temps de lecture estimé : {article.timeToRead} min
    </Badge>
  )

  const imageCard = (
    <ImageCard
      picture={articleImage}
      top={duration}
      className={`${style.article__picture} u-shadow`}
    >
      <ArticleDate
        withMeta={withMeta}
        className={style.article__date}
        date={article.frontmatter.date}
        categories={article.frontmatter.categories}
      />

      <h1
        className={style.article__title}
        itemProp={withMeta ? 'name' : undefined}
      >
        {article.frontmatter.title}
      </h1>
    </ImageCard>
  )

  return (
    <>
      {withLink ? (
        <Link className={style.emphasisLink} to={article.frontmatter.path}>
          {imageCard}
        </Link>
      ) : (
        imageCard
      )}

      {withMeta ? (
        <>
          <meta
            content={format(new Date(article.frontmatter.date), 'yyyy-MM-dd')}
            itemProp="datePublished"
          />

          <meta content={article.frontmatter.title} itemProp="headline" />
          <div
            itemProp="publisher"
            itemScope
            itemType="http://schema.org/Organization"
          >
            <meta content="nkCreation" itemProp="name" />
          </div>
        </>
      ) : null}

      <p
        className={style.article__description}
        itemProp={withMeta ? 'description' : undefined}
      >
        {article.frontmatter.description}
      </p>
    </>
  )
}

export default ArticleEmphasis
