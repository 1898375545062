import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import PropTypes from 'prop-types'
import React from 'react'

const ArticleDate = ({ className, date, withMeta, categories }) => {
  const articleCategories = categories ? ` - ${categories.join(', ')}` : ''

  return (
    <p className={className}>
      {format(new Date(date), 'PPP', { locale: fr })} par{' '}
      {withMeta ? (
        <span itemProp="author" itemScope itemType="http://schema.org/Person">
          <span itemProp="name">Nicolas Leport</span>
        </span>
      ) : (
        'Nicolas Leport'
      )}
      {articleCategories}
    </p>
  )
}

ArticleDate.defaultProps = {
  withMeta: false,
  className: '',
  date: new Date(),
  categories: [],
}

ArticleDate.propTypes = {
  withMeta: PropTypes.bool,
  className: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
}

export default ArticleDate
